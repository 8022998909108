import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query HomePageQuery {
      allMarkdownRemark(
        filter: {
          fields: {
            slug: {
              in: [
                "dance-of-form-and-chaos"
                "transformation-of-the-ugly-duckling"
                "red-damask"
              ]
            }
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              title
              images {
                childImageSharp {
                  fixed(width: 300, height: 300, cropFocus: CENTER) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allMarkdownRemark.edges.map(
    edge => edge.node.frontmatter.images[0].childImageSharp.fixed
  )

  return (
    <Layout>
      <SEO title="Home" />

      <div className="home-links">
        <Link className="home-link" to="/illustrations">
          <h4>Illustrations</h4>
          <Img fixed={images[0]} className="home-link-image" />
        </Link>

        <Link className="home-link" to="/mandalas">
          <h4>Mandalas</h4>
          <Img fixed={images[1]} className="home-link-image" />
        </Link>

        <Link className="home-link" to="/abstract">
          <h4>Abstract</h4>
          <Img fixed={images[2]} className="home-link-image" />
        </Link>
      </div>
    </Layout>
  )
}

export default IndexPage
